import { React, useEffect } from "react";
import AOS from "aos";
import "aos/dist/aos.css";
import ScrollTop from "../Components/Scrollup";
import { Link } from "react-router-dom";
import Testimonial from "../Components/Testimonial";
import banner from "../images/welcome.svg";
import tick_icon from "../images/tickicon.svg";
import power from "../images/power.png";
import gain from "../images/gain.png";
import why_image from "../images/why-join.svg";
import fun from "../images/fun.png";
import curious from "../images/curious.png";
import inspiration from "../images/inspiration.png";
import enterprise from "../images/enterprise.png";
import instagram from "../images/instagram.png";
import Layout from "../Components/layout";
import Milestone from "../Sections/Milestone";
import PastEvents from "../Sections/PastEvents";

function Home() {
  useEffect(() => {
    AOS.init({ duration: 2000 });
  }, []);

  return (
    <Layout>
      <div>
        {/* First Section */}

        <div>
          <div className="bg-yellow mx-10 rounded ">
            <div className="container mt-6 mx-auto md:flex lg:px-20 ">
              <div className="relative font-[poppins] text-white my-10 px-6 py-16 md:w-2/4 ">
                <h2 className="text-2xl lg:text-3xl ">
                  Welcome to India’s first and only non-membership based,
                  <br />
                  <span className="font-semibold">
                    all-women networking club!{" "}
                  </span>
                </h2>
                <p className="pt-6 text-base font-medium font-[raleway] lg:text-lg">
                  If you’re anything like us, you know the challenges you’ve had
                  to face as a woman entrepreneur and you’re looking for a
                  like-minded group of women to connect with and learn from.
                </p>
                <p className="pt-6  text-base font-medium font-[raleway] lg:text-lg">
                  The sky's the limit and there’s no stopping what a bunch of
                  motivated and focused women can achieve.
                </p>
              </div>

              <div className=" w-2/4 my-auto lg:my-10 mx-2 lg:absolute lg:right-0 hidden md:block ">
                <img
                  className="xl:ml-16"
                  src={banner}
                  width="550"
                  height="550"
                  alt="welcome"
                />
              </div>
            </div>
          </div>
        </div>

        {/*  */}

        <div className="text-center font-[raleway]  mt-14 px-6 lg:px-0  lg:mt-28 ">
          <h1 className="uppercase font-[poppins] font-semibold text-2xl  lg:text-2xl ">
            so what <span className="italic">do</span> we do ?
          </h1>
          <hr className="border-t-2 border-purple w-14 my-4 mx-auto" />
          <div className="flex justify-center md:font-semibold lg:font-normal">
            <img
              className="w-4 h-4 mr-2 mt-3 md:my-auto"
              src={tick_icon}
              alt="tickicon"
            />
            <p className="py-2 text-base font-medium md:text-lg lg:text-base ">
              The WNC serves mainly one purpose and that is –
              <span className="font-semibold">NETWORKING!</span>place-items-endplace-items-end
            </p>
          </div>
          <div className="flex justify-center md:font-semibold lg:font-normal">
            <img
              className="w-4 h-4 mr-2 mt-3 lg:my-auto"
              src={tick_icon}
              alt="tickicon"
            />
            <p className="py-2 text-base font-medium md:text-lg lg:text-base ">
              There is literally no other women-only networking community in
              India which requires zero commitment.
            </p>
          </div>

          <h1 className=" font-[poppins] font-semibold text-lg  mt-6 lg:text-xl">
            With WNC-
          </h1>
          <hr className="border-t-2 border-purple w-14 my-4 mx-auto" />

          <div className="flex justify-center md:font-semibold lg:font-normal">
            <img
              className="w-4 h-4 mr-2 my-auto"
              src={tick_icon}
              alt="tickicon"
            />
            <p className="py-2 text-base font-medium lg:text-base ">
              Not a single compulsory meeting.
            </p>
          </div>
          <div className="flex justify-center md:font-semibold lg:font-normal">
            <img
              className="w-4 h-4 mr-2 my-auto"
              src={tick_icon}
              alt="tickicon"
            />
            <p className="py-2 text-base font-medium ">
              No advance commitments.
            </p>
          </div>
        </div>
        {/* Home-Card */}

        <section>
          <div className="px-1 font-[poppins] mt-14 mx-4 md:mx-10 lg:mx-10">
            <div className="md:flex max-w-7xl mx-auto">
              <div className=" shadow border-l-2 border-yellow  h-3/4 px-8 py-6 mx-6 md:w-2/4">
                <div className="flex justify-center py-10 ">
                  <img
                    className="w-24 h-24 lg:w-24 lg:h-24 p-4 bg-orange rounded-full"
                    src={power}
                    alt="power"
                  />
                </div>
                <h2 className="font-semibold lg:text-base">
                  The power is in your hands and the freedom is in your choices
                </h2>
                <p className="text-sm py-4 text-fontgrey tracking-wide font-[Raleway] lg:text-sm lg:leading-7 ">
                  We know that by virtue of being a woman, you are wearing
                  multiple hats and that you have multiple roles to play.
                  Commitment is indeed a heavy burden to bear. Which is why we
                  want you to be able to choose without any time or financial
                  constraints – it’s up to you if you want to attend a meeting
                  or if you want to skip one because you have something else to
                  do that month.
                </p>
                <h3 className="font-bolder text-fontgrey text-base py-2  lg:text-sm">
                  This is perfect for you if you are:
                </h3>
                <ul className="font-semibold text-sm md:text-sm mt-2 lg:text-sm lg:leading-6 ">
                  <li className="py-2 lg:py-1 ">
                    a) a full-time/part-time entrepreneur
                  </li>
                  <li className="py-2 lg:py-1 ">b) a freelancer</li>
                  <li className="py-2 lg:py-1 ">c) an aspiring entrepreneur</li>
                  <li className="py-2 lg:py-1 ">
                    d) someone looking to meet like-minded women
                  </li>
                </ul>
              </div>

              <div className=" shadow border-l-2 border-yellow h-3/4  px-8 py-6 mx-6  mt-16 text-sm md:w-2/4">
                <div className="flex justify-center py-10 ">
                  <img
                    className="w-24 h-24 lg:w-24 lg:h-24 p-4 bg-paleyellow rounded-full"
                    src={gain}
                    alt="gain"
                  />
                </div>
                <h2 className="font-semibold lg:text-base">
                  You have absolutely nothing to lose and everything to gain!
                </h2>
                <p className="text-sm py-4 text-fontgrey tracking-wide font-[Raleway] lg:text-sm lg:leading-7 ">
                  Here at WNC, we plan to host monthly meetings which you can
                  attend from the convenience of your homes as it’s going to be
                  completely online due to the ongoing pandemic. The meetings
                  will happen citywise for women from{" "}
                  <span className="font-semibold text-black">
                    Coimbatore, Chennai, Bangalore, Kolkata, Hyderabad, Manglore
                    and Delhi NCR
                  </span>{" "}
                  separately and overtime, the meetings will shift to an offline
                  forum where we can actually meet and share meaningful
                  experiences.
                </p>
                <p className="text-sm py-4 text-fontgrey tracking-wide font-[Raleway] lg:text-sm lg:leading-7 ">
                  Through our meetings, we hope to form lasting bonds, share
                  knowledge and connect with like-minded women. The networking
                  space is generally male-dominated and we believe an exclusive
                  platform for women like ours will do us a world of wonders.
                </p>
              </div>
            </div>
          </div>
        </section>

        {/* Join Section  */}

        <section>
          <div className="background-image font-[poppins] mt-14 pb-4 mx-4 md:mx-8 lg:mx-10 ">
            <div className="max-w-7xl mx-auto">
              <div className=" mt-4 pt-10">
                <h1 className="uppercase text-xl text-center lg:text-2xl">
                  Why join wnc?
                </h1>
                <hr className="border-t-2 border-purple w-14 my-2 mx-auto" />
              </div>

              <div className="mx-2 px-2 md:flex">
                <div className="md:w-2/4 md:my-auto lg:my-0 ">
                  <img
                    className="mt-4 w-96 h-96 lg:ml-20 lg:w-max lg:h-max "
                    src={why_image}
                    alt="whyjoin"
                  />
                </div>
                <div className="py-6 pl-3 font-[raleway] text-base font-medium md:w-2/4 lg:px-6 lg:text-base">
                  <p className="py-2 pr-4  tracking-wide leading-7  lg:leading-7">
                    The real question is why would you not! Being a woman
                    entrepreneur means you most probably have a product or
                    service that’s targeted at women and talking about it with
                    other women and showing your product/service ensures{" "}
                    <span className="font-semibold">
                      high quality word of mouth marketing{" "}
                    </span>
                    to high quality leads who will be just as interested in
                    their success as you are in theirs. It’s the perfect
                    platform to learn about and network with potential end
                    users.
                  </p>
                  <p className="py-4  tracking-wide leading-7 lg:leading-7">
                    Building trust is the name of the game and that too, the
                    old-fashioned way. Word-of-mouth marketing still has the
                    highest conversion rates for potential customers even in
                    today’s digital age!
                  </p>
                  <p className="py-4  tracking-wide leading-7 lg:leading-7">
                    All of us who have an online presence know that we depend
                    too much on social media to get the word out for us and our
                    businesses. It’s become very obvious that we are overly
                    dependent on one medium for promotion and that we need to
                    diversify. ASAP.
                  </p>
                </div>
              </div>
            </div>

            <h1 className="text-lg text-center mt-6 py-2 px-4">
              <span className="uppercase font-semibold">you</span> are the best
              spokesperson for your brand.
            </h1>
            <h1 className="text-lg text-center mb-6 py-2 px-4">
              So come be a part of something amazing{" "}
              <span className="font-semibold">– inspire and be inspired!</span>
            </h1>
          </div>
        </section>
        {/* <Milestone />
        <PastEvents /> */}

        {/* <Testimonial /> */}
        {/* I Want in */}

        <section>
          <div className="bg-grey font-[poppins] py-14  px-4 md:px-8 lg:px-10 ">
            <div className="max-w-6xl mx-auto">
              <h1 className="uppercase text-xl text-center lg:text-2xl">
                WNC – I WANT IN!
              </h1>
              <hr className="border-t-2 border-purple w-14 my-2 mx-auto" />
              <h2 className="text-center font-semibold text-sm py-4  lg:text-base">
                To join us, there is a strict set of criteria you need to
                satisfy. You have to be:
              </h2>

              <div
                className="py-8 flex justify-evenly flex-wrap gap-2"
                data-aos="fade-right"
              >
                <div className="w-40 h-28 lg:w-48 lg:h-32 rounded-tl-2xl rounded-br-2xl p-4 text-center bg-white shadow mb-8 lg:mb-0">
                  <div>
                    <img
                      className="w-10 h-10 lg:w-12 lg:h-12 mx-auto "
                      src={fun}
                      alt="fun"
                    />
                  </div>
                  <p className="font-semibold text-xs lg:text-sm mt-6">
                    Fun-loving
                  </p>
                </div>
                <div className="w-40 h-28 lg:w-48 lg:h-32 rounded-tl-2xl rounded-br-2xl p-4 text-center bg-white shadow">
                  <div>
                    <img
                      className="w-10 h-10 lg:w-12 lg:h-12 mx-auto "
                      src={curious}
                      alt="curious"
                    />
                  </div>
                  <p className="font-semibold text-xs lg:text-sm mt-6">
                    Curious
                  </p>
                </div>
                <div className="w-40 h-28 lg:w-48 lg:h-32 rounded-tl-2xl rounded-br-2xl p-4 text-center bg-white shadow mb-8 lg:mb-0">
                  <div>
                    <img
                      className="w-10 h-10 lg:w-12 lg:h-12 mx-auto "
                      src={inspiration}
                      alt="inspiration"
                    />
                  </div>
                  <p className="font-semibold text-xs lg:text-sm mt-6">
                    Inspired
                  </p>
                </div>
                <div className="w-40 h-28 lg:w-48 lg:h-32 rounded-tl-2xl rounded-br-2xl p-4 text-center bg-white shadow">
                  <div>
                    <img
                      className="w-10 h-10 lg:w-12 lg:h-12 mx-auto "
                      src={enterprise}
                      alt="enterprise"
                    />
                  </div>
                  <p className="font-semibold text-xs lg:text-sm mt-6">
                    Enterprising
                  </p>
                </div>
                <div className="w-40 h-28 lg:w-48 lg:h-32 rounded-tl-2xl rounded-br-2xl p-3 text-center bg-white shadow">
                  <div>
                    <img
                      className="w-8 h-8 lg:w-8 lg:h-8 mx-auto "
                      src={instagram}
                      alt="instagram"
                    />
                  </div>
                  <p className="card font-semibold text-xs lg:text-xs mt-4 lg:mt-4 leading-snug">
                    Complain about the ever-changing Instagram algorithm
                  </p>
                </div>
              </div>
              <h2 className="text-center font-semibold text-2xl py-4 lg:text-2xl ">
                And that’s it. Welcome aboard!
              </h2>
              <div className="py-4">
                <h2 className="text-center font-semibold text-base  lg:text-base ">
                  This is not all. Knowledge sharing will take place too.
                </h2>
                <p className="text-center text-fontgrey text-base pt-3 lg:text-base ">
                  Didn’t concentrate enough on social media? Guilty!
                </p>
                <p className="text-center text-fontgrey text-base pt-1 lg:text-base ">
                  Didn’t network enough? Guilty!
                </p>
              </div>

              <div className="py-4">
                <h2 className="text-center font-semibold text-base  lg:text-base ">
                  Go from sharing mistakes to sharing knowledge!
                </h2>
                <p className="text-center text-fontgrey text-base pt-3 lg:text-base ">
                  Optimised my website for SEO?
                </p>
                <p className="text-center text-fontgrey text-base pt-1 lg:text-base ">
                  Yeah, I learnt that from WNC!
                </p>
              </div>

              <p className="text-center text-fontgrey text-base pt-3 lg:Rxt-base  ">
                Promoted my products by Word of Mouth without any expenditure on
                advertising?
              </p>
              <p className="text-center text-fontgrey text-base pt-1 lg:text-base ">
                Yeah, I did that with WNC!
              </p>

              <p className="text-center text-fontgrey text-lg py-6 font-bolder  lg:text:base  ">
                What are you waiting for?! Join our community and grow your
                business.
              </p>

              <div className="text-center mt-2">
                <Link to="/register">
                  <button
                    className="px-6 py-2 bg-yellow text-white text-sm font-semibold  rounded lg:text-base"
                    onClick={Top}
                  >
                    REGISTER
                  </button>
                </Link>
              </div>
            </div>
          </div>

          <ScrollTop />
        </section>
      </div>
    </Layout>
  );
}

function Top() {
  window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
}

export default Home;
