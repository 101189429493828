import React from "react";
import Event_Form from "../Components/Event_Form";
import register_image from "../images/register.svg";
import Layout from "../Components/layout"

function Event_Register(){
    
    return(
        <Layout>
        <section className="bg-grey pb-10 h-max w-full font-[poppins]">
          <div className="md:px-8 lg:mx-0 ">
            <div className="flex-none sm:flex  sm:pt-20 max-w-6xl mx-auto ">
              <div className="bg-yellow  px-10 pt-32 text-xl w-2/6 hidden lg:block">
                <p className="font-thin ">
                  India's <span className="font-bold">first</span> and{" "}
                  <span className="font-bold">only</span>
                  <br />
                  non-membership based all{" "}
                  <span className="text-white">women networking club!</span>
                    </p>
                    <img className="mt-10" src={register_image} alt="register" />
              </div>

              <Event_Form />
            </div>
          </div>
        </section>
        </Layout>
    )
}

export default Event_Register;