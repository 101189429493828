import React from "react";
import { Link, useLocation } from "react-router-dom";
import Logo from "../images/logo-header.jpeg";
import whatsapp from "../images/Whatsapp-icon.jpg";
import instagram from "../images/insta-black.png";
import location_icon from "../images/location-icon.jpg";



function Footer (){

    let location = useLocation();
    let path = location.pathname;

    return(
         <div className={path === "/thank-you" ? 'hidden' : 'block'}>
            
            <div className="max-w-7xl  mx-auto">
                <section>
                    
                    <div className='px-4 mt-10 py-4 text-left   lg:px-28 lg:flex lg:justify-between '>
                        <div className='my-auto'>
                            <Link to ='/home'>
                            <img className=' w-40 h-40 mx-auto lg:w-36 lg:h-36 lg:mx-0'onClick={Top} src={Logo} alt="wnc logo"/></Link>
                        </div>

                        <div  className='font-[poppins] text-sm mt-8 lg:mt-0'>
                            <h3 className='text-xl font-semibold text-center md:text-2xl  lg:text-left lg:text-2xl '>Contact us</h3>

                          <div className='md:text-lg  lg:text-base font-thin'>
                                <a href="https://wa.me/+91 93456 77877" target='_blank' rel="noreferrer">
                                    <div className='flex py-2 mt-4 justify-center lg:justify-start'>
                                        <img className='w-5 h-5  lg:w-6 h-6' src={whatsapp} alt="whatsapp icon"/>
                                        <p className='ml-2 my-auto'>+91 93456 77877</p>
                                    </div>
                                </a>
                                <a href="mailto:info@womennetworkingclub.com">
                                    <div className='flex py-2 justify-center lg:justify-start'>
                                        <img className='w-5 h-5 lg:w-6 h-6' src={instagram} alt="envelop icon"/>
                                        <p className='ml-2 my-auto'>info@womennetworkingclub.com</p>
                                    </div>
                                </a>
                                <a href="https://www.google.com/maps/place/Women+Networking+Club/@11.0112286,76.970241,16.99z/data=!4m14!1m7!3m6!1s0x3ba859ac6aaaaaab:0x257d84e2e613ed01!2sWomen+Networking+Club!8m2!3d11.0099576!4d76.971507!16s%2Fg%2F11tdkxd1b9!3m5!1s0x3ba859ac6aaaaaab:0x257d84e2e613ed01!8m2!3d11.0099576!4d76.971507!16s%2Fg%2F11tdkxd1b9" target="_blank" rel="noreferrer" >
                                    <div className='flex py-2 justify-center lg:justify-start'>
                                        <img className='w-5 h-5  lg:w-6 h-6' src={location_icon} alt="navigate icon"/>
                                        <p className='ml-2 mt-1'>46, ATT Colony, Coimbatore - 641018</p>
                                    </div>
                                </a>
                          </div>

                        </div>

                        <div  className='font-[poppins] text-sm text-center lg:text-left'>
                            <h3 className='text-xl font-semibold mt-8 lg:mt-0 md:text-2xl  lg:text-left lg:text-2xl'>Quick links</h3>

                            <ul className='mt-2 font-thin md:text-lg  lg:text-base '>
                                <li className='pt-3 text-slate hover:text-box'onClick={Top}><Link to ='/home'>Home</Link></li>
                                <li className='pt-3 text-slate hover:text-box'onClick={Top}><Link to ='/about'>About</Link></li>
                                <li className='pt-3 text-slate hover:text-box'onClick={Top}><Link to ='/register'>Register</Link></li>
                                <li className='pt-3 text-slate hover:text-box'onClick={Top}><Link to ='/terms'>Terms and Conditions</Link></li>
                            </ul>  

                        </div>
                    </div>
                </section>
            </div>
                <div className='bg-purple text-white text-sm lg:text-sm py-3 text-center lg:flex lg:justify-between px-10'>
                      <p>2024 Women Networking Club. All rights reserved.</p>
                      <p>Developed by Algorism Labs</p>
                </div>
            
        </div>
    );
}
function Top() {
    window.scrollTo({top: 0, left: 0, behavior: 'smooth' });
}
export default Footer;